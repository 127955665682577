<template>
  <div id="contact-info-page-wrapper">
    <vs-popup classContent="popup-example" :title="$t('add_new')" :active.sync="showModal">
      <div v-if="isAdding === 'address' || isEditing === 'address'">
        <div class="vx-col w-full my-1">
          <vs-textarea height="85px" v-validate="'required'" class="mb-0 w-full" name="value" :placeholder="$t('tm')" v-model="pageData.newaddress.value" />
          <span class="text-danger text-sm" v-show="errors.has('value')">{{ errors.first('value') }}</span>
        </div>
        <div class="vx-col w-full my-1">
          <vs-textarea height="85px" v-validate="'required'" class="mb-0 w-full" name="value_ru" :placeholder="$t('ru')" v-model="pageData.newaddress.value_ru" />
          <span class="text-danger text-sm" v-show="errors.has('value_ru')">{{ errors.first('value_ru') }}</span>
        </div>
        <div class="vx-col w-full my-1">
          <vs-textarea height="85px" v-validate="'required'" class="mb-0 w-full" name="value_en" :placeholder="$t('en')" v-model="pageData.newaddress.value_en" />
          <span class="text-danger text-sm" v-show="errors.has('value_en')">{{ errors.first('value_en') }}</span>
        </div>
        <div class="w-full flex mt-4">
          <vs-button type="filled" @click.prevent="submitModalForm" class="block">{{ $t('submit') }}</vs-button>
        </div>
      </div>
      <div v-else-if="isAdding === 'email' || isEditing === 'email'">
        <div class="vx-col w-full my-1">
          <v-select v-validate="'required'" :disabled="isEditing === 'email'" :options="filteredOptions('email')" name="email_key" :placeholder="$t('selectOne')" class="mb-3" v-model="pageData.newemail.key" :label="`name_${$i18n.locale}`" :reduce="opt => opt.key" />
          <span class="text-danger text-sm" v-show="errors.has('email_key')">{{ errors.first('email_key') }}</span>
        </div>
        <div class="vx-col w-full my-1">
          <vs-input v-validate="'required|email'" data-vv-as="field" class="w-full" name="email" :placeholder="$t('fields.email')" v-model="pageData.newemail.value" />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>
        </div>
        <div class="w-full flex mt-4">
          <vs-button type="filled" @click.prevent="submitModalForm" class="block">{{ $t('submit') }}</vs-button>
        </div>
      </div>
      <div v-else-if="isAdding === 'phone' || isEditing === 'phone'">
        <div class="vx-col w-full my-1">
          <v-select v-validate="'required'" :disabled="isEditing === 'phone'" :options="filteredOptions('phone')" name="phone_key" :placeholder="$t('selectOne')" v-model="pageData.newphone.key" :label="`name_${$i18n.locale}`" :reduce="opt => opt.key" />
          <span class="text-danger text-sm" v-show="errors.has('phone_key')">{{ errors.first('phone_key') }}</span>
        </div>
        <div class="vx-col w-full my-1">
          <vs-input v-validate="'required'" data-vv-as="phone" class="w-full" name="phone" :placeholder="$t('fields.phone')" v-model="pageData.newphone.value" />
          <span class="text-danger text-sm" v-show="errors.has('phone')">{{ errors.first('phone') }}</span>
        </div>
        <div class="w-full flex mt-4">
          <vs-button type="filled" @click.prevent="submitModalForm" class="block">{{ $t('submit') }}</vs-button>
        </div>
      </div>
      <div v-else-if="isAdding === 'social' || isEditing === 'social'">
        <div class="vx-col w-full my-1">
          <v-select v-validate="'required'" :options="filteredSocials" name="social_type" :placeholder="$t('selectOne')" v-model="pageData.newsocial.key" label="key" />
          <span class="text-danger text-sm" v-show="errors.has('social_type')">{{ errors.first('social_type') }}</span>
        </div>
        <div class="vx-col w-full my-1">
          <vs-input v-validate="'required'" data-vv-as="social" class="w-full" name="social" placeholder="URL" v-model="pageData.newsocial.value" />
          <span class="text-danger text-sm" v-show="errors.has('social')">{{ errors.first('social') }}</span>
        </div>
        <div class="w-full flex mt-4">
          <vs-button type="filled" @click.prevent="submitModalForm" class="block">{{ $t('submit') }}</vs-button>
        </div>
      </div> 
      <div v-else-if="isAdding === 'work_time' || isEditing === 'work_time'">
        <div class="vx-col w-full my-1">
          <vs-input v-validate="'required'" data-vv-as="work_time" class="w-full" name="work_time" :placeholder="$t('workTime')" v-model="pageData.newwork_time.value" />
          <span class="text-danger text-sm" v-show="errors.has('work_time')">{{ errors.first('work_time') }}</span>
        </div>
        <div class="w-full flex mt-4">
          <vs-button type="filled" @click.prevent="submitModalForm" class="block">{{ $t('submit') }}</vs-button>
        </div>
      </div>
    </vs-popup>
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <template v-else>
      <!-- Contact data -->
      <div class="m-4">
        <fieldset class="vx-row rounded-lg pb-4 pt-1" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{ $t('fields.description') }}</strong></legend>
          <div class="vx-col w-full lg:w-1/2 xl:w-1/3">
            <div class="mt-4 mb-2">
              <h5 class="inline-block">{{ $t('tm') }}</h5>
            </div>
            <quill-editor
              v-if="!contact.uuid || editing"
              ref="myQuillEditor"
              class="editor"
              v-model="contact.value"
              :options="editorOption"
              @focus="showErrMsg = false"
            />
            <div v-else class="content">
              <p v-html="contact.value"></p>
            </div>
          </div>
          <div class="vx-col w-full lg:w-1/2 xl:w-1/3">
            <h5 class="mt-4 mb-2">{{ $t('en') }}</h5>
            <quill-editor
              v-if="!contact.uuid || editing"
              ref="myQuillEditor"
              class="editor"
              v-model="contact.value_en"
              :options="editorOption"
              @focus="showErrMsg = false"
            />
            <div v-else class="content">
              <p v-html="contact.value_en"></p>
            </div>
          </div>
          <div class="vx-col w-full lg:w-1/2 xl:w-1/3">
            <h5 class="mt-4 mb-2">{{ $t('ru') }}</h5>
            <quill-editor
              v-if="!contact.uuid || editing"
              ref="myQuillEditor"
              class="editor"
              v-model="contact.value_ru"
              :options="editorOption"
              @focus="showErrMsg = false"
            />
            <div v-else class="content">
              <p v-html="contact.value_ru"></p>
            </div>
          </div>
          <div v-if="showErrMsg" class="mx-4">
            <span class="text-danger">{{ $t('fillAllFields') }}</span>
          </div>
          <div class="w-full flex mt-4 mx-4">
            <vs-button v-if="contact.uuid && !editing" type="border" @click.prevent="editing = true" class="ml-auto block">{{ $t('edit') }}</vs-button>
            <vs-button v-if="!contact.uuid || editing" type="filled" @click.prevent="submitForm" class="block">{{ $t('submit') }}</vs-button>
            <vs-button v-if="editing" type="border" color="warning" @click.prevent="cancelForm" class="ml-4 block">{{ $t('cancel') }}</vs-button>
          </div>
        </fieldset>
      </div>

      <!-- Addresses -->
      <div class="m-4">
        <fieldset class="vx-row rounded-lg pb-4 pt-1" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{ $t('addresses') }}</strong></legend>
          <template v-if="pageData.address && pageData.address.length">
            <div class="vx-col mb-3 w-full lg:w-1/2 xl:w-1/3">
              <div class="mt-4 mb-2"><h5 class="inline-block">{{ $t('tm') }}</h5></div>
              <div class="content"><p>{{ pageData.address[0].value }}</p></div>
            </div>
            <div class="vx-col w-full lg:w-1/2 xl:w-1/3">
              <h5 class="mt-4 mb-2">{{ $t('en') }}</h5>
              <div class="content"><p>{{ pageData.address[0].value_en }}</p></div>
            </div>
            <div class="vx-col w-full lg:w-1/2 xl:w-1/3">
              <h5 class="mt-4 mb-2">{{ $t('ru') }}</h5>
              <div class="content"><p>{{ pageData.address[0].value_ru }}</p></div>
            </div>
            <div v-if="showErrMsg" class="mx-4"><span class="text-danger">{{ $t('fillAllFields') }}</span></div>
          </template>
          <div class="w-full flex mt-1 mx-4">
            <vs-button v-if="pageData.address && pageData.address.length" type="border" @click.prevent="edit('address', pageData.address[0].uuid)" class="ml-auto block">{{ $t('edit') }}</vs-button>
            <vs-button v-else type="border" class="ml-auto" icon-pack="feather" icon="icon-plus" @click.prevent="add('address')">{{ $t('add_new') }}</vs-button>
          </div>
        </fieldset>
      </div>
      <!-- Phone Numbers -->
      <div class="m-4">
        <fieldset class="vx-row rounded-lg pb-4 pt-1" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{ $t('phoneNumbers') }}</strong></legend>
          <div v-for="phone in pageData.phone" :key="phone.id" class="vx-col w-full mb-3 lg:w-1/2 xl:w-1/3">
            <span>{{ phone.key | capitalize }}</span>
            <div class="content">
              <p>
                {{ phone.value }}
                <vs-td class="inline float-right">
                  <feather-icon v-if="$acl.check('admin')" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" title="Edit banner details" @click.stop="edit('phone', phone.uuid)" />
                  <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" title="Show banner details" @click.stop="deleteData(phone.uuid)" />
                </vs-td>
              </p>
            </div>
          </div>
          <div v-if="pageData.phone && pageData.phone.length < 4" class="vx-col w-full mt-1">
            <vs-button type="border" class="ml-auto" icon-pack="feather" icon="icon-plus" @click.prevent="add('phone')">{{ $t('add_new') }}</vs-button>
          </div>
        </fieldset>
      </div>

      <!-- Email addresses -->
      <div class="m-4">
        <fieldset class="vx-row rounded-lg pb-4 pt-1" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{ $t('emailAddresses') }}</strong></legend>
          <div v-for="email in pageData.email" :key="email.id" class="vx-col w-full mb-3 lg:w-1/2 xl:w-1/3">
            <span>{{ email.key | capitalize }}</span>
            <div class="content">
              <p>
                {{ email.value }}
                <vs-td class="inline float-right">
                  <feather-icon v-if="$acl.check('admin')" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" title="Edit banner details" @click.stop="edit('email', email.uuid)" />
                  <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" title="Show banner details" @click.stop="deleteData(email.uuid)" />
                </vs-td>
              </p>
            </div>
          </div>
          <div v-if="pageData.email && pageData.email.length < 4" class="vx-col w-full mt-1">
            <vs-button type="border" class="ml-auto" icon-pack="feather" icon="icon-plus" @click.prevent="add('email')">{{ $t('add_new') }}</vs-button>
          </div>
        </fieldset>
      </div>

      <!-- Social addresses -->
      <div class="m-4">
        <fieldset class="vx-row rounded-lg pb-4 pt-1" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{ $t('socialAccounts') }}</strong></legend>
          <div v-for="social in pageData.social" :key="social.id" class="vx-col w-full mb-3 lg:w-1/2 xl:w-1/3">
            <span>{{ social.key | capitalize }}</span>
            <div class="content">
              <p>
                {{ social.value }}
                <vs-td class="inline float-right">
                  <feather-icon v-if="$acl.check('admin')" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" title="Edit banner details" @click.stop="edit('social', social.uuid)" />
                  <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" title="Show banner details" @click.stop="deleteData(social.uuid)" />
                </vs-td>
              </p>
            </div>
          </div>
          <div v-if="pageData.social && pageData.social.length < 2" class="vx-col w-full mt-1">
            <vs-button type="border" class="ml-auto" icon-pack="feather" icon="icon-plus" @click.prevent="add('social')">{{ $t('add_new') }}</vs-button>
          </div>
        </fieldset>
      </div>

      <div class="m-4">
        <fieldset class="vx-row rounded-lg pb-4 pt-1" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{ $t('workTime') }}</strong></legend>
          <div v-if="pageData.work_time && pageData.work_time.length" class="vx-col w-full lg:w-1/2 xl:w-1/3">
            <div class="mt-2 content">
              <p>
                {{ pageData.work_time[0].value }}
                <vs-td class="inline float-right">
                  <feather-icon v-if="$acl.check('admin')" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" title="Edit banner details" @click.stop="edit('work_time', pageData.work_time[0].uuid)" />
                </vs-td>
              </p>
            </div>
          </div>
          <div class="vx-col w-auto ml-auto">
            <vs-button v-if="pageData.work_time && !pageData.work_time[0]" type="border" class="" icon-pack="feather" icon="icon-plus" @click.prevent="add('work_time')">{{ $t('add_new') }}</vs-button>
          </div>
        </fieldset>
      </div>
    </template>

  </div>
</template>

<script>
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.snow.css'
export default {
  components: { quillEditor, vSelect },
  data () {
    return {
      pageData: {},
      showErrMsg: false,
      editing: false,
      isAdding: '',
      isEditing: '',
      contact: { uuid: null, value: '', value_en: '', value_ru: '' },
      key: 'contact',
      keys: ['contact', 'phone', 'address', 'email', 'work_time', 'social'],
      editorOption: {
        debug: 'warn',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'align': [] }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'color': [] }, { 'background': [] }],
            ['link']
          ],
          history: { delay: 2000, maxStack: 500, userOnly: true }
        },
        placeholder: 'Describe your company...',
        readOnly: true,
        theme: 'snow'
      },
      cOptions: [
        { key: 'main', name_tm: 'Esasy', name_ru: 'Основной', name_en: 'Main'},
        { key: 'customer_service', name_tm: 'Müşderi hyzmatlary', name_ru: 'Обслуживание клиентов', name_en: 'Customer service'},
        { key: 'corporate', name_tm: 'Hyzmatdashlyk üçin', name_ru: 'По вопросам сотрудничества', name_en: 'Corporate email'},
        { key: 'content_man', name_tm: 'Kontent menejment', name_ru: 'Отдел контента', name_en: 'Content management'}
      ],
      socials: ['instagram', 'imo'],
      isLoading: true
    }
  },
  computed: {
    formValid () { return this.contact.value !== '' && this.contact.value_en !== '' && this.contact.value_ru !== '' },
    showModal: {
      get () { return !!this.isAdding || !!this.isEditing },
      set () {
        this.isAdding = ''
        this.isEditing = ''
      }
    },
    filteredSocials () {
      return this.socials.filter((s) => { return !this.pageData.social.map(e => e.key).includes(s) })
    },
    filteredOptions () {
      return (model) => {
        return this.cOptions.filter((s) => { return !this.pageData[model].map(e => e.key).includes(s.key) })
      }
    }
  },
  methods: {
    add (key) {
      this.isAdding = key
      this.$set(this.pageData, `new${key}`, {})
    },
    edit (key, uuid) {
      this.isEditing = key
      const editObj = this.pageData[key].find(el => el.uuid === uuid)
      this.pageData[`new${key}`] = Object.assign(editObj)
    },
    deleteData (uuid) {
      this.$http.delete(`/texts/delete/${uuid}`).then(() => { this.fetchAllData() })
    },
    fetchAllData () {
      this.keys.forEach(key => {        
        this.$http.get('/texts', { params: { key } }).then(res => {
          if (res.data) {
            this.$set(this.pageData, key, res.data)
            this.pageData[key].forEach(data => {
              if (data.key.includes('email') || data.key.includes('phone') || data.key.includes('social')) {
                const nkey = data.key.split('_')
                nkey.shift()
                data.key = nkey.join('_')
              }
            })
          }
        })
      })
    },
    async fetchData () {
      this.$http.get('/texts', { params: { key: this.key } }).then(res => {
        if (res.data && res.data.length) {
          this.contact.uuid = res.data[0].uuid
          this.contact.value = (res.data[0].value)
          this.contact.value_en = (res.data[0].value_en)
          this.contact.value_ru = (res.data[0].value_ru)
        }
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        this.$vs.notify({
          title: 'Error',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    async cancelForm () {
      this.editing = false
      await this.fetchAllData()
    },
    submitModalForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.isAdding) {
            let private_key = ''
            const newKey = `new${this.isAdding}`
            if (this.isAdding === 'phone' || this.isAdding === 'email' || this.isAdding === 'social') { 
              private_key = `${this.isAdding}_${this.pageData[newKey].key}`
            } else {
              private_key = this.isAdding
            }
            this.$http.post('/texts/add', {
              key: private_key,
              value: this.pageData[newKey].value,
              value_en: this.pageData[newKey].value_en,
              value_ru: this.pageData[newKey].value_ru
            }).then(() => {
              // console.log(res)
              this.isAdding = ''
              this.fetchAllData()
            })
          } else if (this.isEditing) {
            let private_key = ''
            const newKey = `new${this.isEditing}`
            if (this.isEditing === 'phone' || this.isEditing === 'email') { 
              private_key = `${this.isEditing}_${this.pageData[newKey].key}`
            } else {
              private_key = this.isEditing
            }
            this.$http.patch(`/texts/edit/${this.pageData[newKey].uuid}`, {
              key: private_key,
              value: this.pageData[newKey].value,
              value_en: this.pageData[newKey].value_en,
              value_ru: this.pageData[newKey].value_ru
            }).then(() => {
              // console.log(res)
              this.isAdding = ''
              this.isEditing = ''
              this.fetchAllData()
            })
          }
        }
      })
    },
    async submitForm () {
      if (this.formValid) {
        if (!this.contact.uuid) {
          await this.$http.post('/texts/add', {
            key: this.key,
            value: this.contact.value,
            value_en: this.contact.value_en,
            value_ru: this.contact.value_ru
          }).then(() => { this.fetchData() })
        } else {
          await this.$http.patch(`/texts/edit/${this.contact.uuid}`, {
            key: this.key,
            value: this.contact.value,
            value_en: this.contact.value_en,
            value_ru: this.contact.value_ru
          }).then(res => {
            this.editing = false
            this.contact.uuid = res.data.uuid
            this.contact.value = (res.data.value)
            this.contact.value_en = (res.data.value_en)
            this.contact.value_ru = (res.data.value_ru)
          })
        }
      } else {
        this.showErrMsg = true
      }
    }
  },
  async mounted () {
    await this.fetchAllData()
    await this.fetchData()
  }
}
</script>

<style lang="scss">
#contact-info-page-wrapper {
  .buttons-container {
    margin: -10px -10px 5px;
    .vs-button {
      margin: 10px;
    }
  }
  .content {
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 15px;
    background-color: white;
    p {
      padding: 0px 2px;
      &.ql-indent-1 {
        padding-left: 30px;
      }
      &.ql-indent-2 {
        padding-left: 60px;
      }
      span {
        &.ql-size-small {
          font-size: 12px;
        }
        &.ql-size-large {
          font-weight: 500;
          font-size: 22px;
        }
        &.ql-size-huge {
          font-size: 32px;
          font-weight: 600;
        }
      }
      ul {
        // text-decoration: underline;
        padding-left: 30px;
        li {
          list-style-type: disc;
        }
      }
      ol {
        // text-decoration: underline;
        padding-left: 30px;
        li {
          list-style-type: decimal;
        }
      }
    }
  }
  .quill-editor.editor {
    height: 200px;
    border-radius: 8px;
    overflow: hidden;
    background-color: white;
    border: 1px solid #ccc;
    padding: 0 5px 10px 0;
    .ql-toolbar.ql-snow {
      border: unset !important;
      border-bottom: 1px solid #ccc;
    }
    .ql-container {
      // height: auto;
      ::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.1);
        border-radius: 10px;
        width: 6px;
        background-color: #F5F5F5;
      }
      ::-webkit-scrollbar {
        width: 6px;
        position: absolute;
        z-index: 1;
        background-color: #ffffff;
      }
      ::-webkit-scrollbar-thumb {
        width: 6px;
        border-radius: 10px;
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #ccc;
        :hover {
          cursor: pointer !important;
        }
      }
    }
  }
}
</style>
